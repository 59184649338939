type EmployeeRole = "company" | "employee";

export enum VideosessionStatus {
  OPEN = 'OPEN', // just created
  REINVITED = 'REINVITED', // 30 mins after creation
  NOTIFIED = 'NOTIFIED', // 60 mins after creation
  TIME_OUT = 'TIME_OUT', // no one accept
  ACCEPTED = 'ACCEPTED', // accepted more than 5 days before start
  PAID = 'PAID', // accepted, less than 5 days to start, payment intent created
  CHANGED_SLOW = 'CHANGED_SLOW', // company request changes more than 48 hrs before meeting start
  CHANGED_FAST = 'CHANGED_FAST', // company request changes less than 48 hrs before meeting start
  STARTED = 'STARTED', // company request changes less than 48 hrs before meeting start
  CANCELLED = 'CANCELLED', // cancelled by company or interpreter
  SUCCEED = 'SUCCEED', // videosession is finished, everything is paid
  FAILED = 'FAILED', // videosession is finished, some problems with payment
  INTERPRETER_MISSED = 'INTERPRETER_MISSED', // company has joined, interpreter has not
  COMPANY_MISSED = 'COMPANY_MISSED', // interpreter has joined, company has not
  FREE_FINISHED = 'FREE_FINISHED', // session was free and finished
}
export interface ITokens {
  accessToken: string;
  refreshToken: string;
  notificationSecret: string;
}
export interface ILoginData {
  email: string;
  password: string;
  rememberMe?: boolean;
}
export interface IUser {
  id: number;
  profileId: number;
  email: string;
  role: "admin";
  refreshToken: string;
  notificationSecret: string;
  firstName: string;
  lastName: string;
  phone: string;
  isBlocked: boolean;
  twoFA: boolean;
  language?: string;
}

export interface IEmployee {
  id: number;
  email: string;
  role: EmployeeRole;
  costCenter: string;
  user: IUser | null;
  departments?: ICompanyDepartment[];
}

export interface ILanguage {
  id: number;
  title_en: string;
  title_de: string;
  code: string;
}
type UserGender = "male" | "female" | "diverse";
type LanguageLevel = "a1" | "a2" | "b1" | "b2" | "c1" | "c2";
export type CertificateType = "cv" | "diploma" | "reference";
interface IRating {
  overall?: IStats;
  skills?: IStats;
  punctuality?: IStats;
  videoQuality?: IStats;
  finishedCalls?: number;
  missedCalls?: number;
  cancelledCalls?: number;
}
interface IStats {
  count: number;
  average: number;
}
interface IAddress {
  street: string;
  postalCode: string;
  city: string;
  country: string;
  id: number;
}
interface IWorkingLanguage {
  type: "sign" | "spoken";
  proficiency?: LanguageLevel;
  code: string;
}
export interface ICertificate {
  id: number;
  name: string;
  type: CertificateType;
}
interface ILanguagePair {
  code: string;
  level: LanguageLevel;
  id: number;
}
interface ILogo {
  id: number;
  name: string;
}
export interface IExpertiseArea {
  id: number;
  title_en: string;
  title_de: string;
}
interface IExperience {
  title: string;
  description: string;
  start: string;
  end: string;
}

interface IRequirementError {
  code: string;
  reason: string;
  requirement: string;
}

export interface IStripeStatus {
  detailsSubmitted: boolean;
  requirements: boolean;
  requirementsErrors?: IRequirementError[];
  pendingVerification: boolean;
  payoutsEnabled: boolean;
}

export interface VacationTime {
  id: number;
  startDate: Date;
  endDate: Date;
  interpreter: IInterpreterUser;
}
export interface IInterpreterUser extends IUser {
  id: number;
  profileId: number;
  address: IAddress;
  bornAt: Date;
  gender: UserGender;
  rating?: IRating;
  certificates: ICertificate[];
  languages: ILanguagePair[];
  stripeAccount?: string;
  isActive: boolean;
  isReviewed: boolean;
  nativeLanguages: ILanguage[];
  workingLanguages: IWorkingLanguage[];
  expertiseAreas: IExpertiseArea[];
  chargeVat?: boolean;
  vatId?: string;
  academicDegree: boolean;
  studyFields: string;
  academicTitles: string;
  employmentStatus?: string;
  selfEmployed: boolean;
  about?: string;
  comments?: string;
  stripeOnboarded?: boolean;
  statusProof?: {
    name: string;
    id: number;
  };
  picture?: {
    name: string;
  };
  experience?: IExperience[];
  price: number;
  createdAt: Date;
  website?: string;
}

export interface ISessionInterpreter {
  id: number;
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
}

export interface ICreateVideosessionFormData {
  title: string;
  description: string;
  costCenter?: string;
  timeRange: TimeRange;
  gender?: UserGender | "none";
  translateFrom: string;
  startTime: Date;
  translateTo?: string;
  dialect?: string;
  translateToSign?: string;
  type: "spoken" | "sign";
  expertiseArea: number;
  workWithPreviousInterpreters: boolean;
  additionalFields?: IAdditionalOption[];
  isFree: boolean;
  companyId?: number;
  userId?: number;
  departmentId?: number | null;
}

export interface ICreateVideosessionFormDataExtended
  extends ICreateVideosessionFormData {
  interpretersToInvite?: number[];
  allowToSearchAnother?: boolean;
  dialect?: string;
  userId: number;
}

export interface ICreateVideosessionData
  extends ICreateVideosessionFormDataExtended {
  code: string;
  secret: string;
}

export interface IInterpreterWithUser extends IInterpreterUser {
  user: IUser;
}

export type TimeRange = "m30" | "h1" | "h2" | "more";

type IntlTitlesType = { en: string; de: string; fr: string; it: string };
export interface IAdditionalOption {
  value?: string;
  name: string;
  title: IntlTitlesType;
}

declare interface IHistory {
  spendings?: number;
  earnings?: number;
  startedAt?: Date;
  spentMinutes?: number;
  allowForFree?: boolean;
}

export interface IVideosession {
  id: number;
  languagePair: string;
  status: VideosessionStatus;
  costCenter: string;
  title: string;
  description?: string;
  notes?: string;
  creator: IUser;
  initiator?: IUser;
  participant?: IUser;
  company: ICompany;
  interpreter: IInterpreterWithUser;
  expertiseArea: IExpertiseArea;
  translateFrom: number;
  translateTo: number;
  translateToSign: number;
  spentMinutes?: number;
  startTime: Date;
  timeRange: TimeRange;
  additionalFields?: IAdditionalOption[];
  history?: IHistory;
  declined?: IInterpreterWithUser[];
  invited?: IInterpreterWithUser[];
  notInvited?: IInterpreterWithUser[];
  earningPaid?: number;
  department?: ICompanyDepartment
  customId?: string
}

export interface LogData {
  interpreter: string;
  time: Date;
  reason?: string;
  user?: string;
}

export interface LogDataUser {
  user: string;
  time: Date;
}

export type SessionLogData = Date | LogData | LogDataUser;

export interface SessionLog {
  name: string;
  data: SessionLogData;
}
export interface SessionLogs {
  id: number;
  sessionId: number;
  translateTo: string;
  translateFrom: string;
  expertiseArea: string;
  events: {
    [name: string]: Date | LogData;
  };
}

interface IVideosessionSnapshot extends IVideosession {
  interpreterName: string;
  creatorName: string;
  companyName: string;
}

export interface ISpending {
  amountDiscounted: number;
  companyId: number;
  earning: number;
  earningPaid: number;
  id: number;
  interpreterId: number;
  spending: number;
  spendingPaid: number;
  startTime: Date;
  startedTime: Date;
  filterTime: Date;
  costCenter: string;
  videosessionSnapshot: IVideosessionSnapshot;
  invoicePayment: boolean;
}

export interface IInterpreterEditForm extends IUser, IAddress {
  workingLanguages: IWorkingLanguage[];
  bornAt: Date;
  nativeLanguages: string[];
  certificates?: ICertificate[];
  statusProof?: {
    name: string;
    id: number;
  };
  onboarded: boolean;
  comments: string;
}

export interface ICompany {
  id: number;
  name: string;
  about: string;
  primaryMethod: string | null;
  stripeId: string;
  isBlocked: boolean;
  requireDepartment: boolean;
  companyDepartments: ICompanyDepartment[];
  specialization: IExpertiseArea | null;
  user: IUser;
  address: IAddress;
  billingAddress?: IAddress;
  billingName?: string;
  billingName2?: string;
  billingName3?: string;
  logo: ILogo;
  employees: IEmployee[];
  bonusBalance: number;
  additionalPrice: number;
  outstandingBalance: number;
  invoicePayment: boolean;
  customFeedbackUrl?: string;
  customFeedbackTitle?: IntlTitlesType;
  blockedInterpreters: IInterpreterWithUser[]
}

export interface ICompanyDepartment {
  id: number;
  title_en: string;
  title_de: string;
  title_fr: string;
  title_it: string;
  costCenter?: string;
  parentDepartment?: ICompanyDepartment;
}

export interface IFilterItems {
  current: number;
  pageSize: number;
  status?: string | null;
  email?: string;
  companyName?: string;
  interpreterEmail?: string;
  balance?: string;
  firstName?: string;
  lastName?: string;
  onboarded?: string;
  nativeLanguages?: string[];
  workingLanguages?: string[];
  ratingRange?: number[];
  expertiseArea?: number;
  startDate?: string;
  startTime?: string;
  timeRange?: TimeRange;
  dialect?: string;
  translateFrom?: string;
  translateTo?: string;
  translateSign?: string;
  type?: string;
  gender?: UserGender;
  from?: string;
  to?: string;
  withCustomId?: boolean
}

interface IVoucherRedeem {
  id: number;
  company: ICompany;
  voucher: IVoucher;
}
export interface IVoucher {
  id?: number;
  code: string;
  validBefore: string;
  redeems?: IVoucherRedeem[];
  maxRedeems: number;
  bonusAmount: number;
}
export interface Feedback {
  id: number;
  videosession: IVideosession;
  overall: number;
  personalSkills: number;
  professionalSkills: number;
  punctuality: number;
  soundQuality: number;
  videoQuality: number;
  interpreter: IInterpreterWithUser;
}
